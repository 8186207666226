@import '../../../main';

.modal__ {
    &header {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        width: 100%;

        h3 {
            margin: 0;
            font-size: 1.25rem;
            padding: 0.5rem 0;
            width: 100%;

            text-align: center;
        }

        button {
            @extend .button;

            position: absolute;
            right: 0;
            padding: 0.75rem 2rem;
        }
    }

    &form__ {
        &field {
            @extend .input;

            width: 40%;
            margin-top: 1.5rem;
            padding: 0.5rem 1rem;
            border-radius: 0.5rem;

            font-size: 1rem;

            &_number {
                width: 2rem;
                margin: 0;
            }
        }

        &numBlock {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            width: 45%;
            margin-top: 1.5rem;
        }

        &numLabel {
            font-weight: 600;
            font-size: 1rem;
            margin-right: 0.5rem;
        }

        &alert {
            color: $error-color;
            font-weight: 600;
            font-size: 1rem;
            width: 80%;
            text-align: center;

            margin: 0.5rem 0 1rem;
        }

        &submit {
            @extend .button;
            
            padding: 0.75rem 2rem;
        }

        &checkbox {
            -webkit-appearance: none;
            appearance: none;
            margin: 0;
          
            width: 2.5em;
            height: 2.5em;
            background-color: #fff;
            border-radius: 0.25em;
            transform: translateY(-0.075em);
          
            display: grid;
            place-content: center;

            &::before {
                content: "";
                width: 1.5em;
                height: 1.5em;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: bottom left;
                transition: 120ms transform ease-in-out;
                background-color: $button-bg;
            }
              
            &:checked::before {
                transform: scale(1);
            }
              
            &:focus {
                outline: 0;
                outline-offset: max(2px, 0.15em);
            }
        }
    }
}

@media screen and (max-width: 675px) {
    .modal__ {
        &header {
            h3 {
                text-align: left;
            }
        }
    
        &form__ {
            &field {
                width: 65%;
                margin-top: 1rem;
    
                &_number {
                    width: 2rem;
                    margin: 0;
                }
            }
    
            &numBlock {
                
                width: 65%;
                margin-top: 1rem;
            }
    
            &alert {
                width: 80%;
            }
    
            &submit {
                padding: 1rem 3rem;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .modal__ {
        &header {
            position: static;
            flex-direction: column-reverse;
    
            h3 {
                margin-top: 1rem;
                text-align: center;
            }
    
            button {
                position: static;
            }
        }
    
        &form__ {
            &field {
                width: 90%;
                padding: 0.75rem 1rem;
    
                &_number {
                    width: 2rem;
                    margin: 0;
                }
            }
    
            &numBlock {
                justify-content: space-between;
                
                width: 100%;
            }
    
            &alert {
                width: 100%;
            }

            &checkbox {
                width: 2em;
                height: 2em;

                &::before {
                    width: 1.25em;
                    height: 1.25em;
                }
            }
        }
    }
}